var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.type
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Velg Kategori",
      "items": _vm.area,
      "item-text": "text",
      "item-value": "value",
      "rules": [function (v) {
        return /(?:true|false)/.test(v) || 'Velg emnetype.';
      }],
      "required": ""
    },
    on: {
      "change": _vm.changeType
    },
    model: {
      value: _vm.categoryType,
      callback: function callback($$v) {
        _vm.categoryType = $$v;
      },
      expression: "categoryType"
    }
  })], 1), !_vm.subCourseArea ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Hovedområde",
      "rules": [function (v) {
        return !!v || 'Fyll inn navn på hovedområde.';
      }],
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1) : _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Hovedområde",
      "items": _vm.getSubjectRegister,
      "item-text": function itemText(item) {
        return item.code + ' - ' + item.description.charAt(0).toUpperCase() + item.description.slice(1);
      },
      "item-value": "id",
      "rules": [function (v) {
        return !!v || 'Velg hovedområde.';
      }],
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.data.parentId,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "parentId", $$v);
      },
      expression: "data.parentId"
    }
  })], 1), _vm.subCourseArea ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Kursområde",
      "rules": [function (v) {
        return !!v || 'Fyll inn navn på kursområde.';
      }],
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Internkode",
      "rules": [function (v) {
        return !!v || 'Fyll inn internkode.';
      }, function (v) {
        return /^\d{3}$/.test(v) || 'Internkoden skal bestå av 3 siffer. ';
      }],
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.data.code,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "code", $$v);
      },
      expression: "data.code"
    }
  })], 1), _vm.subCourseArea ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Velg SSB kode",
      "items": _vm.getSSBCodes,
      "item-text": function itemText(item) {
        return item.ssbCode + ' - ' + item.description.charAt(0).toUpperCase() + item.description.slice(1);
      },
      "item-value": "ssbCode",
      "rules": [function (v) {
        return !!v || 'Velg SSB kode.';
      }],
      "required": ""
    },
    model: {
      value: _vm.data.ssbCode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "ssbCode", $$v);
      },
      expression: "data.ssbCode"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "value": false,
      "inset": "",
      "rules": [function (v) {
        return /(?:true|false)/.test(v) || 'Velg emnetype.';
      }],
      "label": _vm.data.isActive ? 'Er aktiv' : 'Inaktiv'
    },
    model: {
      value: _vm.data.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "isActive", $$v);
      },
      expression: "data.isActive"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }