var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', [_c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Hoved- og kursområder")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk i områder",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _vm.canUpsert ? _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "newSubject"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('new');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Nytt emne ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.getSubjectRegister,
      "search": _vm.search,
      "filter": _vm.filter
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.$emit('open', value);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-chevron-down ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis kursområde")])])];
      }
    }, {
      key: "item.isActive",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.$emit('display', item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis hovedområde")])]), _vm.canUpsert ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.$emit('edit', item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger hovedområde")])]) : _vm._e()];
      }
    }], null, true)
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }