var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.showModal = false;
      }
    }
  }, [_c('SubjectRegisterModal', {
    attrs: {
      "headline": _vm.modalHeadline,
      "type": _vm.modalType
    },
    on: {
      "close": function close($event) {
        _vm.showModal = false;
      }
    }
  })], 1) : _vm._e(), _c('v-slide-x-reverse-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [!_vm.displaySubjects ? _c('MainCourseTable', {
    staticClass: "mt-3",
    attrs: {
      "canUpsert": _vm.canUpsert
    },
    on: {
      "open": function open(val) {
        return _vm.children(val);
      },
      "display": function display(item) {
        return _vm.displayCourse(item);
      },
      "edit": function edit(item) {
        return _vm.editCourse(item);
      },
      "new": _vm.newSubject
    }
  }) : _c('SubjectTable', {
    attrs: {
      "canUpsert": _vm.canUpsert,
      "expanded": _vm.subjects
    },
    on: {
      "close": function close($event) {
        _vm.displaySubjects = false;
      },
      "display": function display(item) {
        return _vm.displaySubject(item);
      },
      "edit": function edit(item) {
        return _vm.editSubject(item);
      },
      "new": _vm.newSubject
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }