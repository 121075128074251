
import { ApiHierarchicalAreaDtoType } from "@/api/generated/Api";
import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";
import MainCourseTable from "@/components/administration/subject-register/MainCourseTable.vue";
import SubjectRegisterModal from "@/components/administration/subject-register/SubjectRegisterModal.vue";
import SubjectTable from "@/components/administration/subject-register/SubjectTable.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";

interface ComponentData {
  displaySubjects: boolean;
  subjects: ApiHierarchicalAreaDtoType;
  showModal: boolean;
  modalHeadline: string;
  modalType: string;
}

export default defineComponent({
  name: "SubjectRegisterPage",
  data(): ComponentData {
    return {
      displaySubjects: false,
      subjects: {
        id: 0,
        description: "",
        parentId: 0,
        children: [],
        ssbCode: "",
        code: "",
        isActive: false,
      },
      showModal: false,
      modalHeadline: "",
      modalType: "",
    };
  },
  computed: {
    ...mapGetters("subjectRegister", ["getSubjectRegister"]),
    canUpsert() {
      return authService.hasRoles(AuthRole.ModifyCourseArea);
    },
  },
  components: { MainCourseTable, SubjectTable, BaseModal, SubjectRegisterModal },
  methods: {
    ...mapActions("subjectRegister", ["fetchSubjectRegister"]),
    children(val: any) {
      this.displaySubjects = true;

      this.subjects = (this as any).getSubjectRegister[
        (this as any).getSubjectRegister.findIndex((i: any) => i.id === val)
      ];
    },
    newSubject() {
      this.$store.commit("subjectRegister/SET_SUBJECT", {});
      this.showModal = true;
      this.modalHeadline = "Nytt emne";
      this.modalType = "add";
    },
    displayCourse(item: any) {
      this.$store.commit("subjectRegister/SET_SUBJECT", item);
      this.showModal = true;
      this.modalHeadline = "";
      this.modalType = "display";
    },
    editCourse(item: any) {
      this.$store.commit("subjectRegister/SET_SUBJECT", item);
      this.showModal = true;
      this.modalHeadline = "Rediger emne";
      this.modalType = "edit";
    },
    displaySubject(item: any) {
      this.$store.commit("subjectRegister/SET_SUBJECT", item);
      this.showModal = true;
      this.modalHeadline = "";
      this.modalType = "display";
    },
    editSubject(item: any) {
      this.$store.commit("subjectRegister/SET_SUBJECT", item);
      this.showModal = true;
      this.modalHeadline = "Rediger emne";
      this.modalType = "edit";
    },
  },
  async mounted() {
    await (this as any).fetchSubjectRegister();
  },
});
