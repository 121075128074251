
import { ApiHierarchicalAreaDtoType } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { PropType, defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "SubjectTable",
  components: {
    BaseTableFiltered,
    BaseLayout,
  },
  props: {
    expanded: {
      type: Object as PropType<ApiHierarchicalAreaDtoType>,
      required: true,
    },
    canUpsert: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ search: "" }),
  computed: {
    ...mapGetters("subjectRegister", ["getSubjectRegister"]),
    ...mapGetters("plans", ["getDefaultOpt"]),
    hadToBeHere(): any {
      return [this.expanded];
    },
    subHeaders() {
      return [
        { text: "Internkode", value: "id" },
        { text: "Handlinger", value: "actions" },
        { text: "Kursområde", value: "description" },
        { text: "SSB kode", value: "ssbCode" },
        { text: "Status", value: "isActive" },
      ];
    },
    mainHeaders() {
      return [
        { text: "", value: "actions" },
        { text: "Internkode", value: "code" },
        { text: "Hovedområde", value: "description" },
        { text: "Status", value: "isActive" },
      ];
    },
  },
  methods: {
    ...mapActions("subjectRegister", ["fetchSubjectRegister"]),
    ...mapActions("plans", ["fetchDefaultOptions"]),
    newSubject() {
      this.$store.commit("subjectRegister/SET_SUBJECT", {});

      const modal = {
        type: "add",
        headline: "Nytt emne",
      };
      this.$store.commit("hoc/SET_MODAL_TYPE", modal);
      this.$store.commit("hoc/OPEN_MODAL", "SubjectRegister");
    },
  },
  async mounted() {
    await (this as any).fetchSubjectRegister();
    await (this as any).fetchDefaultOptions();
  },
});
